<template>
  <div class="summary-table">
    <h2>
      {{ title }} ({{ companies.length }})
    </h2>
    <data-table
      :data="companies"
      show-summary
      sum-text="Total"
    >
      <el-table-column
        label="Name"
        prop="human_name"
        width="275px"
        :sortable="true"
      >
        <template slot-scope="scope">
          <link-button
            :to="{ name: 'CompanyOverview', params: { cid: scope.row.id } }"
            type="primary"
          >
            {{ scope.row.human_name }}
          </link-button>
        </template>
      </el-table-column>
      <el-table-column
        label="Status"
        prop="status"
        :sortable="true"
      />
      <el-table-column
        label="Count"
        :prop="prop"
        :sortable="true"
      />
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable'
import LinkButton from '@/components/buttons/LinkButton'

export default {
  name: 'SummaryTable',
  components: {
    'data-table': DataTable,
    'link-button': LinkButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    prop: {
      type: String,
      required: true
    },
    companies: {
      type: Array,
      required: true
    }
  }
}
</script>
