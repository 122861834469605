<template>
  <div class="property-tables">
    <div v-for="item in properties" :key="item.company.id">
      <h3>
        {{ item.company.human_name }} ({{ item.properties.length }})
      </h3>
      <data-table :data="item.properties">
        <el-table-column
          label="Name"
          prop="name"
          width="275px"
          :sortable="true"
        >
          <template slot-scope="scope">
            <link-button
              :to="{ name: 'PropertyEdit', params: { cid: item.company.id, id: scope.row.id } }"
              type="primary"
            >
              {{ scope.row.name }}
            </link-button>
          </template>
        </el-table-column>
        <el-table-column
          label="Status"
          prop="status"
        />
      </data-table>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable'
import LinkButton from '@/components/buttons/LinkButton'

export default {
  name: 'PropertyTables',
  components: {
    'data-table': DataTable,
    'link-button': LinkButton
  },
  props: {
    properties: {
      type: Array,
      required: true
    }
  }
}
</script>
